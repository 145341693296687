<template>
  <div>
    <Header />
    <div :class="iSParameterError ? 'boc' : ''" v-if="iSstatus == 0">
      <div class="content">
        <div class="bgi">
          <div class="info">
            <!-- 资料 -->
            <div class="info-z">
              <!-- 左 -->
              <div class="info-left">
                <div class="left-a">
                  <p class="left-nickname">{{ nickName }}</p>
                  <div class="left-icon">
                    <img v-if="UisVipMember == 1" src="../../assets/images/tubiao/yezai_member_auth_aa.png" alt=""
                      title="也在会员" />
                    <img v-if="realAuthStatus == 2" src="../../assets/images/tubiao/yezai_auth_aa.png" alt=""
                      title="实名认证" />
                  </div>
                  <div class="Btn-box" @click="guanzhu">
                    <img src="../../assets/images/message/yezai_aixin.png" alt="" />
                    {{ gztext }}
                  </div>
                </div>
                <p class="left-id">{{ id }}</p>
                <p class="left-zi">
                  <span v-if="age">{{ age }}岁 </span>
                  <span v-if="nativePlaceCityName">&nbsp;|&nbsp;{{ nativePlaceCityName }}</span>
                  <span v-if="maritalStatus">&nbsp;|&nbsp;{{ maritalStatus }}
                  </span>
                  <span v-if="monthIncome">&nbsp;|&nbsp;{{ monthIncome }}
                  </span>
                  <span v-if="education">&nbsp;|&nbsp;{{ education }} </span>
                </p>
              </div>
              <!-- 右 -->
              <div class="info-right">
                <div class="right-a">
                  <div class="a" @click="pingbi">
                    <img src="../../assets/images/info/reduce.png" alt="" />
                    <!-- 屏蔽此人 -->
                    <p style="margin-right: 14px">{{ pbtext }}</p>
                  </div>
                  <!-- 屏蔽蒙层 -->
                  <div class="mongolia" v-show="mongolia">
                    <div class="xg-phone">
                      <i class="icon el-icon-close" @click="Btn(1)"></i>
                      <div class="top">
                        <p>屏蔽该用户</p>
                      </div>
                      <div class="line"></div>
                      <p class="title">
                        将TA屏蔽后，TA不能再对你发起任何互动，<br />
                        TA将进入你的黑名单
                      </p>
                      <div class="Btn">
                        <div class="cancel" @click="Btn(1)">取消</div>
                        <div class="confirm" @click="queren(1)">确认</div>
                      </div>
                    </div>
                  </div>
                  <div class="a" @click="report">
                    <img src="../../assets/images/info/tishi.png" alt="" />
                    <p>举报此人</p>
                  </div>
                  <!-- 举报 -->
                </div>
                <!-- 查看最近登陆时间 -->
                <div class="right-b right-b-a" v-if="isVipMember == 0">
                  <img src="../../assets/images/info/deng.png" alt="" />
                  <!-- <router-link ></router-link> -->
                  <p>
                    <a style="color: #fff" href="/n/yezaiMember">
                      查看最后登录时间</a>
                  </p>
                </div>
                <div class="right-b" v-else-if="isVipMember == 1">
                  <img src="../../assets/images/info/deng.png" alt="" />
                  <p>{{ $moment(loginDate).format("L LT") }}</p>
                </div>
              </div>
            </div>
            <div class="info-m">
              <img :src="avatar || src" alt="" class="big-img" />

              <!-- <div
                class="big-img"
                :style="{
                  backgroundImage: `url(${avatar || src})`,
                }"
              ></div> -->
              <!-- <img
              class="big-img"
                :src="isAvatar == 0 ? ManSrc : avatar || src"
                v-if="sex == 0"
                alt=""
              />
              <img
              class="big-img"
                :src="isAvatar == 0 ? WomanSrc : avatar || src"
                v-if="sex == 1"
                alt=""
              /> -->
              <!-- 轮播图 -->
              <!-- <div class="info-lunbo" v-if="isImg">
                <div class="card-carousel-wrapper">
                  <div
                    class="card-carousel--nav__left"
                    @click="moveCarousel(-1)"
                    :disabled="atHeadOfList"
                  ></div>
                  <div class="card-carousel">
                    <div class="card-carousel--overflow-container">
                      <div
                        class="card-carousel-cards"
                        :style="{
                          transform:
                            'translateX' + '(' + currentOffset + 'px' + ')',
                        }"
                      >
                        <div
                          class="card-carousel--card"
                          v-for="(item, index) in items"
                          :key="index"
                        >
                          <div class="card-carousel--card--footer">
                            <el-image
                              style="width: 100px; height: 100px; display: flex"
                              :src="item.imgage"
                              :preview-src-list="isAvatar == 1 ? listImg : []"
                              :z-index="zIndex"
                              @click="previewSrcList"
                            >
                            </el-image>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-carousel--nav__right"
                    @click="moveCarousel(1)"
                    :disabled="atEndOfList"
                  ></div>
                </div>
              </div> -->
              <!-- :indicatorPosition="none" -->
              <!-- " -->
              <div class="info-info-lunbo" v-if="isImg">
                <el-carousel :loop="false" :autoplay="false" indicator-position="none" arrow="always" height="100px"
                  style="width: 600px">
                  <el-carousel-item class="el-car-item" v-for="(list, index) in items" :key="index">
                    <!-- <img
                      v-for="(imgList, index2) in list"
                      :key="index2"
                      class="top-img"
                      :src="imgList.imgage"
                      alt=""
                    /> -->
                    <el-image :preview-src-list="isAvatar == 1 ? listImg : []" :z-index="zIndex"
                      v-for="(imgList, index2) in list" :key="index2" class="top-img" :src="imgList.imgage" alt=""
                      @click="previewSrcList">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>

              <div class="no-photo" v-if="!isImg">
                <span class="icon"></span>
                {{ `${sex == 0 ? "他" : "她"}还没发布照片` }}
              </div>
              <div class="info-bottom">
                <div v-if="MessageStatus" @click="send">
                  <img src="../../assets/images/info/info-mess_a.png" alt="" />
                  <p>打招呼</p>
                </div>
                <div v-else>
                  <!-- <img src="../../assets/images/info/info-mess.png" alt="" /> -->
                  <img src="../../assets/images/info/info-mess_a.png" alt="" />
                  <p>已打招呼</p>
                </div>
                <div @click="messageList">
                  <img src="../../assets/images/info/info-mail.png" alt="" />
                  <p>发消息</p>
                </div>
                <div @click="Services">
                  <img src="../../assets/images/info/info-xibie.png" alt="" />
                  <p>红娘牵线</p>
                </div>
              </div>
            </div>

            <!-- 也在会员 v-if="isVipMember == 0" -->
            <!-- 购买也在币会员 -->
            <div class="info-a">
              <div class="a-box" :style="{ background: 'url(' + XXimg + ') no-repeat' }">
                <p>也在会员</p>
                <p>搜索提前，查看登录时间，尊贵标识， 助你寻爱！</p>
              </div>
              <div class="b-box">
                <ul ref="bgc">
                  <li v-for="(item, index) in shapList.slice(0, 3)" :key="index" :class="yezaiB == index ? 'active' : ''"
                    @click="nu(item.id)">
                    <p>{{ item.goodName.replace("也在会员", "") }}</p>
                    <p class="b-box_day">{{ item.goodIntroduce }}</p>
                    <p v-if="index == 0" class="remai">热卖</p>
                    <p class="b-box_money">
                      ¥{{ Number(item.goodPrice) / 100 }}
                    </p>
                    <img class="b-box_img" :src="yezaiB == index ? dui1 : dui" alt="" />
                  </li>
                </ul>
                <div class="mai-box">
                  <div class="mai" @click="fn">立即购买</div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <div class="bg-info">
        <div class="info-zi">
          <div class="zi-left">
            <div class="left-box">
              <p>内心独白</p>
              <div :class="showTotal ? 'total-introduce' : 'detailed-introduce'">
                <div class="intro-content" :title="introduce" ref="desc">
                  <span class="merchant-desc">
                    {{
                      introduce ||
                      `${sex == 0 ? "他" : "她"}还很懒,还没内心独白`
                    }}
                  </span>
                  <div class="unfold" @click="showTotalIntro" v-if="showExchangeButton">
                    <p>{{ exchangeButton ? "展开" : "收起" }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="left-box">
              <p>个人资料</p>
              <div class="box2">
                <div class="info_div">
                  <!-- v-if="info.maritalStatus" -->
                  <div class="m-btn purple" v-if="info.maritalStatus">
                    {{ info.maritalStatus }}
                  </div>
                  <div class="m-btn purple" v-if="info.education">
                    {{ info.education }}
                  </div>
                  <div class="m-btn purple" v-if="info.age">
                    {{ info.age + "岁" }}
                  </div>
                  <div class="m-btn purple" v-if="info.workAddrCityName">
                    {{ "工作地区:" + info.workAddrCityName }}
                  </div>
                  <div class="m-btn purple" v-if="info.monthIncome">
                    {{ info.monthIncome }}
                  </div>
                  <div class="m-btn purple" v-if="info.weight">
                    {{
                      `体重:${info.weight > 29 ? info.weight : "30"}kg${info.weight > 29 ? "" : "以下"
                        }`
                    }}
                  </div>
                  <!-- <div class="m-btn purple" v-if="info.shap"> -->
                  <div class="m-btn purple" v-if="Boolean(info.shape)">
                    {{ "体型:" + info.shape }}
                  </div>
                  <!-- info.constellatory -->
                  <div class="m-btn purple" v-if="info.constellatory">
                    {{ info.constellatory }}
                  </div>
                  <div class="m-btn purple" v-if="info.nation">
                    {{ info.nation }}
                  </div>
                  <div class="m-btn purple" v-if="info.height">
                    {{ info.height + "cm" }}
                  </div>
                </div>
                <div class="info_box">
                  <div class="m-btn purple" v-if="info.nativePlaceCityName">
                    {{ "籍贯:" + info.nativePlaceCityName }}
                  </div>
                  <div class="m-btn purple" v-if="info.wantChildren">
                    {{ "是否想要孩子:" + info.wantChildren }}
                  </div>
                  <div class="m-btn purple" v-if="info.marriedTime">
                    {{ "何时结婚:" + info.marriedTime }}
                  </div>
                  <div class="m-btn purple" v-if="info.occBigCategory">
                    {{ "职业:" + info.occBigCategory }}
                  </div>
                  <div class="m-btn purple" v-if="info.buyCarStatus">
                    {{ "是否买车:" + info.buyCarStatus }}
                  </div>
                  <div class="m-btn purple" v-if="info.houseStatus">
                    {{ "住房情况:" + info.houseStatus }}
                  </div>

                  <div class="m-btn purple" v-if="info.doDrink">
                    {{ "是否喝酒:" + info.doDrink }}
                  </div>
                  <div class="m-btn purple" v-if="info.doSmake">
                    {{ "是否吸烟:" + info.doSmake }}
                  </div>
                </div>
              </div>
            </div>
            <div class="left-box">
              <p>兴趣爱好</p>
              <div class="box3">
                <p>
                  喜欢的一道菜：<span v-if="info.favoriteDishes">{{
                    info.favoriteDishes
                  }}</span>
                  <span v-if="!info.favoriteDishes">未填写</span>
                </p>
                <p>
                  欣赏的一个名人：
                  <span v-if="info.favoriteCelebrities">{{
                    info.favoriteCelebrities
                  }}</span>
                  <span v-if="!info.favoriteCelebrities">未填写</span>
                </p>
                <p>
                  喜欢的一首歌：
                  <span v-if="info.favoriteSongs">{{
                    info.favoriteSongs
                  }}</span>
                  <span v-if="!info.favoriteSongs">未填写</span>
                </p>
                <p>
                  喜欢的一本书：
                  <span v-if="info.favoriteBooks">{{
                    info.favoriteBooks
                  }}</span>
                  <span v-if="!info.favoriteBooks">未填写</span>
                </p>
                <p class="jjj">
                  喜欢做的事：<span v-if="info.likeDoing">{{
                    info.likeDoing
                  }}</span>
                  <span v-if="!info.likeDoing">未填写</span>
                </p>
              </div>
            </div>
            <div class="left-box">
              <p>择偶条件</p>
              <div class="box4">
                <!-- 年龄 -->
                <div class="m-btn" v-if="!(
                  mateSelection.ageMin == null &&
                  mateSelection.ageMax == null
                )
                  ">
                  {{ mateSelection.ageMin }}{{ mateSelection.ageMax }}
                </div>
                <!-- 身高 !(
                      mateSelection.ageMin == null &&
                      mateSelection.ageMax == null
                    )  mateSelection.heightMin && mateSelection.heightMax-->
                <div class="m-btn" v-if="!(
                  mateSelection.heightMin == null &&
                  mateSelection.heightMax == null
                )
                  ">
                  {{ mateSelection.heightMin + mateSelection.heightMax }}
                </div>
                <!-- 薪资 -->
                <div class="m-btn" v-if="!(
                  mateSelection.monthIncomeMax == null &&
                  mateSelection.monthIncomeMin == null
                )
                  ">
                  月薪:{{ mateSelection.monthIncomeMin
                  }}{{ mateSelection.monthIncomeMax }}
                </div>
                <!-- 学历 -->
                <div class="m-btn" v-if="mateSelection.education">
                  {{ mateSelection.education }}
                </div>
                <div class="m-btn" v-if="mateSelection.maritalStatus">
                  {{ mateSelection.maritalStatus }}
                </div>
                <div class="m-btn" v-if="mateSelection.shape">
                  {{ "体型:" + mateSelection.shape }}
                </div>
                <div class="m-btn" v-if="mateSelection.workAddrCityName">
                  {{ "工作地区:" + mateSelection.workAddrCityName }}
                </div>
                <div class="m-btn" v-if="mateSelection.existsChildren">
                  {{ mateSelection.existsChildren }}
                </div>
                <div class="m-btn" v-if="mateSelection.wantChildren">
                  {{ "是否想要孩子:" + mateSelection.wantChildren }}
                </div>
                <div class="m-btn" v-if="mateSelection.doSmake">
                  {{ mateSelection.doSmake }}
                </div>
                <div class="m-btn" v-if="mateSelection.doDrink">
                  {{ mateSelection.doDrink }}
                </div>
                <div class="m-btn" v-if="iSmateSelection">不限</div>
              </div>
            </div>
          </div>
          <!-- :class="isVipMember == 1 ? 'zi-right-Mo' : ''" -->
          <div class="zi-right" v-if="dataList.length > 0">
            <div class="zi-right-box">
              <p class="title">猜你喜欢</p>
              <div class="box1" @click="go">
                <img class="box1-img" src="../../assets/images/info/huanyizu.png" alt="" />
                <p style="width: 50px; color: #fd686e">换一组</p>
              </div>
            </div>
            <div>
              <ul>
                <li v-for="item in dataList" :key="item.id" @click="ziliao(item.id)">
                  <div class="left-box">
                    <img :src="item.avatarImg" alt="" />
                  </div>
                  <div class="right-box">
                    <!-- <p class="name">{{ item.nickName }}</p> -->
                    <div class="box-name">
                      <p>
                        {{
                          item.nickName.substring(0, 6) +
                          (item.nickName.length > 7 ? "..." : "")
                        }}
                      </p>
                      <img v-if="item.isVipMember == 1" src="../../assets/images/tubiao/yezai_member_auth_aa.png" alt=""
                        title="也在会员" />
                      <img v-if="item.realAuthStatus == 2" src="../../assets/images/tubiao/yezai_auth_aa.png" alt=""
                        title="实名认证" />
                    </div>
                    <p class="age">
                      <!-- {{ item.age }}岁 <span>{{ item.height }}cm</span> -->
                      <span v-if="item.age">{{ item.age }}岁</span>
                      <span v-if="item.height">&nbsp;|&nbsp;{{ item.height }}cm</span>
                    </p>
                    <p class="jj">
                      <!-- 土生土长的西安男生display: -webkit-box; /*
                      -webkit-box-orient: vertical; */ /*! autoprefixer: off */
                      -webkit-box-orient: vertical; /* autoprefixer: on */
                      -webkit-line-clamp: 2; overflow: hidden; -->
                      <span v-if="item.introduce">
                        {{
                          item.introduce.substring(0, 20) +
                          (item.introduce.length > 19 ? "..." : "")
                        }}
                      </span>
                      <!-- <span v-else>{{
                        `${sex == 0 ? "他" : "她"}很适合你哦，赶紧去看看吧!`
                      }}</span> -->
                      <span v-else>{{
                        `${item.sex == 0 ? "他" : "她"
                          }很适合你哦，赶紧去看看吧!`
                      }}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 账号异常 -->
    <div class="box" v-if="iSstatus != 0">
      <div class="abnormal">
        <div class="bgi">
          <div class="info">
            <!-- 资料 -->
            <div class="info-z">
              <!-- 左 -->
              <div class="info-left">
                <div class="left-a">
                  <p class="left-nickname">{{ nickName }}</p>
                  <div class="left-icon">
                    <img v-if="UisVipMember == 1" src="../../assets/images/tubiao/yezai_member_auth_aa.png" alt=""
                      title="也在会员" />
                    <img v-if="realAuthStatus == 2" src="../../assets/images/tubiao/yezai_auth_aa.png" alt=""
                      title="实名认证" />
                  </div>
                  <div class="Btn-box b">
                    <img src="../../assets/images/message/yezai_aixin.png" alt="" />
                    {{ gztext }}
                  </div>
                </div>
                <p class="left-id">{{ id }}</p>
                <p class="left-zi">
                  <span v-if="age">{{ age }}岁 </span>
                  <span v-if="nativePlaceCityName">| {{ nativePlaceCityName }}
                  </span>
                  <span v-if="education">| {{ education }} </span>
                  <span v-if="maritalStatus">| {{ maritalStatus }} </span>
                  <span v-if="height"> | {{ height }}cm </span>
                  <span v-if="monthIncome">| {{ monthIncome }} </span>
                </p>
              </div>
              <!-- 右 -->
              <div class="info-right">
                <div class="right-a">
                  <div class="a b">
                    <img src="../../assets/images/info/reduce.png" alt="" />
                    <!-- 屏蔽此人 -->
                    <p style="margin-right: 14px">{{ pbtext }}</p>
                  </div>
                  <div class="mongolia" v-show="mongolia">
                    <div class="xg-phone">
                      <i class="icon el-icon-close"></i>
                      <div class="top">
                        <p>屏蔽该用户</p>
                      </div>
                      <div class="line"></div>
                      <p class="title">
                        将TA屏蔽后，TA不能再对你发起任何互动，也无法
                        关注你，TAj将进入你的黑名单
                      </p>
                      <div class="Btn">
                        <div class="cancel" @click="Btn(1)">取消</div>
                        <div class="confirm" @click="queren(1)">确认</div>
                      </div>
                    </div>
                  </div>
                  <div class="a" @click="jubao">
                    <img src="../../assets/images/info/tishi.png" alt="" />
                    <p>举报此人</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- 提示话语 -->
            <div class="info-b">
              <!-- src="../../assets/images/message/tou.png" -->
              <img style="border-radius: 4px" :src="avatar || src" alt="" class="big-img" />

              <img src="../.../../../assets/images/info/infoAbnormal.png" alt="" />

              <p>
                温馨提示：该用户（{{
                  id
                }}）账号异常或存在不诚信行为，为了你的征婚安全，该账号已被限制，如果你的权益被侵犯,
                <span @click="JubaoYY">点击此处对该用户进行举报</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bgc"></div>
    </div>

    <!-- 举报蒙层 -->
    <div class="jubao-mongolia" v-if="reportMongolia">
      <div class="xg-phone">
        <i class="icon el-icon-close" @click="Btn(2)"></i>
        <div class="top">
          <p>举报该用户</p>
        </div>
        <div class="line"></div>
        <p class="title-tip">
          将 <span style="color: #fd686e">{{ nickName }}</span> 举报
        </p>
        <div class="title">
          <span><i style="color: red"> *</i> 举报类型</span>
          <el-select v-model="value" placeholder="请选择" popper-class="popper_select">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <p class="tip" v-if="JulX">请选择举报类型</p>
        </div>
        <div class="liyou">
          <span> <i style="color: red"> *</i> 举报理由</span>
          <div class="title-reason">
            <textarea id="text" v-model="rise" @input="riseInput" maxlength="500"
              placeholder="请填写举报理由，详细描述你所遇到的情况，以便客服人员快速为你处理" />
            <span class="number">{{ extent }}/500</span>
          </div>
          <p class="liyou-tip" v-if="iSliyou">请填写举报理由</p>
        </div>
        <div class="jietu">
          <span><i style="color: red"> *</i> 证据截图（最多6张）</span>
          <div class="jietu-a">
            <!-- 举报截图上传 -->
            <el-upload action :http-request="UpLoad" list-type="picture-card" :limit="numLL" :file-list="fileList"
              :on-success="handleAvatarSuccess2" :on-remove="handleRemoveLL">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <p class="jietu-tip" v-if="iSjietu">至少上传一张证据截图</p>
        </div>
        <div class="info">
          <span>你的名字</span>
          <el-input v-model="YouName" placeholder="请填写你的姓名"></el-input>
        </div>
        <div class="info">
          <span>你的手机号</span>
          <el-input v-model="YouPhone" placeholder="请填写真实手机号"></el-input>
        </div>
        <div class="Btn">
          <div class="cancel" @click="Btn(2)">取消</div>
          <div class="confirm" @click="juBaoQueren(2)">提交</div>
        </div>
      </div>
    </div>

    <!-- 头像未上传 -->
    <div class="tks" v-if="iSShyness">
      <div class="SF">
        <div class="Sf-title">
          <p>提示</p>
          <p class="el-icon-close" @click="istkClose()"></p>
        </div>
        <!-- 主要内容 -->
        <div class="Sf-content">
          <p>你还没有上传头像，上传头像后才可以看别人的照片</p>
        </div>
        <div class="Sf-Btn">
          <div class="Sf-Btn-left" @click="istkClose()">
            <p>取消</p>
          </div>
          <div class="Sf-Btn-right" @click="istkCloseQR()">
            <p>确认</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 参数错误 -->
    <div class="ParameterError" v-if="iSParameterError">
      <div class="SF">
        <div class="Sf-title">
          <p>提示</p>
          <!-- <p class="el-icon-close" @click="istkClose()"></p> -->
        </div>
        <!-- 主要内容 -->
        <div class="Sf-content">
          <p>参数错误</p>
        </div>
        <div class="Sf-Btn">
          <div class="Sf-Btn-right" @click="iSParameterErrorClose()">
            <p>确认</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 确认取消 -->
    <div class="ParameterError" v-if="iSreportMongolia">
      <div class="SF">
        <div class="Sf-title">
          <p>提示</p>
          <!-- <p class="el-icon-close" @click="istkClose()"></p> -->
          <i class="icon el-icon-close" @click="iSreportMongoliaClose(1)"></i>
        </div>
        <!-- 主要内容 -->
        <div class="Sf-content">
          <p>是否放弃已输入的内容？</p>
        </div>
        <div class="Sf-Btn">
          <div class="Sf-Btn-left" @click="iSreportMongoliaClose(1)">
            <p>取消</p>
          </div>
          <div class="Sf-Btn-right" @click="iSreportMongoliaClose(2)">
            <p>确认</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layout/header";
import Footer from "@/components/layout/footer";
import local from "@/api/common/local.js";
import { albumList } from "@/api/member/member.js";
// followin 关注 shield 屏蔽 infofollow
import {
  infocur,
  query,
  followin,
  infofollow,
  shield,
  shieldInfo,
  browsing,
} from "@/api/login/login.js";
import {
  ApplicationServices,
  informInfom,
  guessLike,
  subTk,
} from "@/api/member/member.js"; // sendmessage,
import { goodList } from "@/api/my/my.js";
import { client, getFileNameUUID } from "@/api/common/oss/oss.js";
import { ossL } from "@/api/common/oss/ossL.js";
import { loveLove } from "@/api/my/my.js";
import DB from "@/assets/js/Greeting.js";
export default {
  data() {
    return {
      iSreportMongolia: false,
      iSParameterError: false, //参数
      iSShyness: false, //头像未上传蒙层
      zIndex: 9999,
      XXimg: require("../../assets/images/info/xinbgi.png"),
      XXimgL: require("../../assets/images/info/XXbgc.png"),
      dui: require("@/assets/images/tubiao/dui.png"),
      dui1: require("@/assets/images/tubiao/dui1.png"),
      isImg: false,
      a: false,
      radio: 0,
      idcard: "",
      id: "",
      nickName: "",
      avatar: "", //头像
      nativePlaceCityName: "", //城市
      age: "", //年龄
      education: "", //学历
      height: "", //身高
      monthIncome: "", //月收入
      maritalStatus: "",
      introduce: "", //内心独白
      sex: "", //性别
      i: {},
      gztext: "关注",
      //默认头像
      src: require("@/assets/images/message/tou.png"),
      ManSrc: require("@/assets/images/message/manTou.png"),
      WomanSrc: require("@/assets/images/message/womanTou.png"),
      yezaiB: 0,
      yezaiA: 0,
      listImg: [],
      pbtext: "屏蔽此人",
      mongolia: false,
      reportMongolia: false,
      falg: true,
      fl: true,
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      constellations: [
        {
          value: "牡羊座(03.21-04.20)",
          label: 0,
        },
        {
          value: "金牛座(04.21-05.20)",
          label: 1,
        },
        {
          value: "双子座(05.21-06.21)",
          label: 2,
        },
        {
          value: "巨蟹座(06.22-07.22)",
          label: 3,
        },
        {
          value: "狮子座(07.23-08.22)",
          label: 4,
        },
        {
          value: "处女座(08.23-09.22)",
          label: 5,
        },
        {
          value: "天秤座(09.23-10.22)",
          label: 6,
        },
        {
          value: "天蝎座(10.23-11.21)",
          label: 7,
        },
        {
          value: "射手座(11.22-12.21)",
          label: 8,
        },
        {
          value: "摩羯座(12.22-01.19)",
          label: 9,
        },
        {
          value: "水瓶座(01.20-02.19)",
          label: 10,
        },
        {
          value: "双鱼座(02.20-03.20)",
          label: 11,
        },
      ], //星座
      shapes: [
        {
          value: "保密",
          label: "0",
        },
        {
          value: "一般",
          label: "1",
        },
        {
          value: "瘦长",
          label: "2",
        },
        {
          value: "运动员型",
          label: "3",
        },
        {
          value: "比较胖",
          label: "4",
        },
        {
          value: "体格魁梧",
          label: "5",
        },
        {
          value: "壮实",
          label: "6",
        },
        {
          value: "苗条",
          label: "7",
        },
        {
          value: "高大美丽",
          label: "8",
        },
        {
          value: "丰满",
          label: "9",
        },
        {
          value: "富线条美",
          label: "10",
        },
      ], //体型
      existsChildrens: [
        {
          value: "没有孩子",
          label: "0",
        },
        {
          value: "有孩子且住在一起",
          label: "1",
        },
        {
          value: "有孩子且偶尔会在一起住",
          label: "2",
        },
        {
          value: "有孩子但不在身边",
          label: "3",
        },
      ], //有无孩子
      wantChildrens: [
        {
          value: "视情况而定",
          label: "0",
        },
        {
          value: "想要孩子",
          label: "1",
        },
        {
          value: "不想要孩子",
          label: "2",
        },
        {
          value: "以后再告诉你",
          label: "3",
        },
        {
          value: "以后再告诉你",
          label: "4",
        },
      ], //是否想要孩子
      //是否吸烟
      doSmakess: [
        {
          value: "不要吸烟",
          label: "0",
        },
        {
          value: "可以吸烟",
          label: "1",
        },
      ],
      doSmakes: [
        // {
        //   value: "不限",
        //   label: null,
        // },
        {
          value: "不吸烟",
          label: "0",
        },
        {
          value: "稍微抽一点烟",
          label: "1",
        },
        {
          value: "烟抽得很多",
          label: "2",
        },
        {
          value: "社交场合会抽烟",
          label: "3",
        },
      ],
      doDrinks: [
        // {
        //   value: "不限",
        //   label: null,
        // },
        {
          value: "不喝酒",
          label: "0",
        },
        {
          value: "稍微喝一点酒",
          label: "1",
        },
        {
          value: "酒喝得很多",
          label: "2",
        },
        {
          value: "社交场合会喝酒",
          label: "3",
        },
      ],
      //是否喝酒
      doDrinkss: [
        {
          value: "不要喝酒",
          label: "0",
        },
        {
          value: "可以喝酒",
          label: "1",
        },
      ],
      //有无照片
      avaters: [
        {
          value: "有",
          label: "1",
        },
      ],
      marriedTimes: [
        {
          value: "认同闪婚",
          label: 0,
        },
        {
          value: "一年内",
          label: 1,
        },
        {
          value: "两年内",
          label: 2,
        },
        {
          value: "三年内",
          label: 3,
        },
        {
          value: "时机成熟就结婚",
          label: 4,
        },
      ], //何时结婚
      buyCarStatus: [
        {
          value: "已买车",
          label: "0",
        },
        {
          value: "未买车",
          label: "1",
        },
      ], //买车
      houseStatus: [
        {
          value: "和家人同住",
          label: "0",
        },
        {
          value: "已购房",
          label: "1",
        },
        {
          value: "租房",
          label: "2",
        },
        {
          value: "打算婚后购房",
          label: "3",
        },
        {
          value: "住在单位宿舍",
          label: "4",
        },
      ],
      marriages: [
        {
          value: "未婚",
          label: "0",
        },
        {
          value: "离婚",
          label: "1",
        },
        {
          value: "丧偶",
          label: "2",
        },
      ], //婚姻
      ageMin: "",
      info: {},
      mateSelection: "",
      isVipMember: "",
      options: [
        {
          value: "色情相关",
          label: "色情相关",
        },
        {
          value: "头像、虚假资料",
          label: "头像、虚假资料",
        },
        {
          value: "婚托、饭托、酒托等",
          label: "婚托、饭托、酒托等",
        },
        {
          value: "骚扰信息",
          label: "骚扰信息",
        },
        {
          value: "诈骗钱财、虚假中奖信息",
          label: "诈骗钱财、虚假中奖信息",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      value: "",
      extent: 0,
      rise: "",
      dialogImageUrl: "",
      dialogVisible: false,
      YouName: "",
      YouPhone: "",
      Total: "",
      PageNum: 1,
      dataList: [],
      loginDate: "",
      pages: "",
      c: "", //
      currentOffset: 0,
      windowSize: 3,
      paginationFactor: 220,
      b: true,
      items: [],
      UisVipMember: "",
      realAuthStatus: "",
      UmemberId: "",
      iSstatus: "",

      //上传图片
      ImgList: "",
      fileList: [],
      numLL: 6,
      //举报图片路径,
      jubaoImg: [],
      JulX: false,
      iSjietu: false,
      iSliyou: false,
      isAvatar: "", //是否上传头像
      shapList: [],
      iSmateSelection: false,
      isSex: "",
      // jj: [],
      //测试
      // 是否展示所有文本内容
      showTotal: true,
      // 显示展开还是收起
      exchangeButton: true,
      // 是否显示展开收起按钮
      showExchangeButton: false,
      rem: "",
      //打招呼状态
      MessageStatus: true,
    };
  },
  created() {
    this.qing();
    this.gz();
    this.pb();
    this.Like();
    this.tkL();
    this.csh();
    // if (this.UmemberId != this.$route.query.id) {
    //   this.liulanAdd();
    //   console.log('数据_____________________',this.UmemberId,'12',this.$route.query.id)
    // }
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    // atEndOfList() {
    //   return (
    //     this.currentOffset <=
    //     this.paginationFactor * -1 * (this.items.length - this.windowSize)
    //   );
    // },
    // atHeadOfList() {
    //   return this.currentOffset === 0;
    // },
  },
  methods: {
    showTotalIntro() {
      console.log(this.showTotal);
      this.showTotal = !this.showTotal;
      this.exchangeButton = !this.exchangeButton;
    },
    getRem() {
      console.log("getRem");
      const defaultRem = 16;
      let winWidth = window.innerWidth;
      console.log("winWidth:" + winWidth);
      let rem = (winWidth / 375) * defaultRem;
      return rem;
    },
    // 初始化商品
    async csh() {
      let dat = {
        categoryId: 1,
      };
      const { code, data } = await goodList(dat);
      if (code == 0) {
        // console.log(data);
        this.shapList = data;
      }
    },
    istkClose() {
      this.iSShyness = false;
    },
    istkCloseQR() {
      this.iSShyness = false;
      const that = this;
      setTimeout(function () {
        let rel = that.$router.resolve({
          path: "/n/user/_album",
        });
        window.open(rel.href, "_blank");
      }, 0);
    },
    iSParameterErrorClose() {
      // UmemberId
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: this.UmemberId,
        },
      });
      // console.log('sss',rel);
      window.open(rel.href, "_self"); //当前页面打开  ===>  _blank打开一个新页面
      // window.location.reload();
    },
    //提示上传头像
    previewSrcList() {
      if (this.isAvatar == 0) {
        this.iSShyness = true;
        // this.$message({
        //   message: "请上传头像后查看",
        //   center: true,
        //   offset: 100,
        // });
      }
    },
    //登录环信账号
    login() {
      const _that = this;
      var options = {
        user: this.UmemberId, // ----------------------------------------------------------------------------------(当前用户账号)
        // accessToken: local.get("token"),
        pwd: "yezai_user@easemob",
        // appKey: _that.WebIM.config.appkey,
        appKey: _that.$WebIM.config.appkey,
      };
      // _that.WebIM.conn.open(options);
      _that.$WebIM.conn.open(options);

      // _that.WebIM.conn.listen({
      _that.$WebIM.conn.listen({
        onOpened: function (message) {
          console.log("00--", message);
        },
        onTextMessage: function (message) {
          console.log("onTextMessage", message);
        },
      });
    },
    async liulanAdd() {
      let a = {
        browseMemberId: this.$route.query.id,
      };

      const { code, data } = await browsing(a);
      if (code == 0) {
        console.log(data);
      }
    },
    //lll
    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    //轮播
    // moveCarousel(direction) {
    //   // Find a more elegant way to express the :style. consider using props to make it truly generic
    //   if (direction === 1 && !this.atEndOfList) {
    //     this.currentOffset -= this.paginationFactor;
    //   } else if (direction === -1 && !this.atHeadOfList) {
    //     this.currentOffset += this.paginationFactor;
    //   }
    // },
    //举报理由长度----------
    riseInput() {
      let textVal = this.rise.length; //输入的长度
      this.extent = textVal;
    },
    //---举报
    JubaoYY() {
      // console.log('0000000000');
      this.reportMongolia = true;
    },
    nu(id) {
      // console.log(this.$refs.bgc);
      // if (id == 0) {
      //   this.yezaiB = 0;
      // } else if (id == 1) {
      //   this.yezaiB = 1;
      // } else if (id == 2) {
      //   this.yezaiB = 2;
      // }
      if (id == "1") {
        this.yezaiB = 0;
      } else if (id == "2") {
        this.yezaiB = 1;
      } else if (id == "3") {
        this.yezaiB = 2;
      }
    },
    nuL(id) {
      if (id == 0) {
        this.yezaiA = 0;
      } else if (id == 1) {
        this.yezaiA = 1;
      } else if (id == 2) {
        this.yezaiA = 2;
      } else if (id == 3) {
        this.yezaiA = 3;
      } else if (id == 4) {
        this.yezaiA = 4;
      }
    },
    fnL() {
      console.log("00", this.yezaiA);
      let rel = this.$router.resolve({
        path: "/yezaigold",
      });
      window.open(rel.href, "_blank");
    },
    //改变状态
    async guanzhu() {
      if (this.isSex == this.sex) {
        this.$message.error({
          message: "同性不能互动",
          offset: 100,
        });
      } else {
        this.falg = !this.falg;
        console.log("====", this.falg);
        let c;
        if (this.falg == true) {
          c = 1;
        } else {
          c = 0;
        }
        let Data = this.$route.query.id;
        // let d = local.get("access_token");
        let a = {
          // token: d,
          followMemberId: Data,
          opFlag: c,
        };
        const { code, msg } = await followin(a);
        if (code == 0) {
          // console.log("000000000", msg);
          if (c == 1) {
            this.$message({
              message: "取消关注",
              type: "success",
              offset: 100,
            });
            this.gz();
            this.tkL();
          } else {
            this.$message({
              message: "关注成功",
              type: "success",
              offset: 100,
            });
            this.gz();
            this.tkL();
          }
        } else if (code == 1) {
          this.$message.error({
            message: msg,
            offset: 100,
          });
          this.tkL();
        }
      }
      // this.falg = !this.falg;
      // console.log("====", this.falg);
      // let c;
      // if (this.falg == true) {
      //   c = 1;
      // } else {
      //   c = 0;
      // }
      // let Data = this.$route.query.id;
      // let d = local.get("access_token");
      // let a = {
      //   token: d,
      //   followMemberId: Data,
      //   opFlag: c,
      // };
      // const { code, msg } = await followin(a);
      // if (code == 0) {
      //   // console.log("000000000", msg);
      //   if (c == 1) {
      //     this.$message({
      //       message: "取消关注",
      //       type: "success",
      //       offset: 100,
      //     });
      //     this.gz();
      //     this.tkL();
      //   } else {
      //     this.$message({
      //       message: "关注成功",
      //       type: "success",
      //       offset: 100,
      //     });
      //     this.gz();
      //     this.tkL();
      //   }
      // } else if (code == 1) {
      //   this.$message.error({
      //     message: msg,
      //     offset: 100,
      //   });
      //   this.tkL();
      // }
    },
    // //获取当前关注状态
    async gz() {
      let Data = this.$route.query.id;
      // let a = local.get("access_token");
      let b = {
        // token: a,
        followMemberId: Data,
      };
      const { code, data } = await infofollow(b);
      if (code == 0) {
        // console.log("信息", data);
        // this.gztext = data;
        if (data.opFlag == "0") {
          this.falg = false;
          this.gztext = "已关注";
        } else {
          this.falg = true;
          this.gztext = "关注";
        }
      }
    },
    //获取当前屏蔽状态
    async pb() {
      let Data = this.$route.query.id;
      // let a = local.get("access_token");
      let b = {
        // token: a,
        shieldMemberId: Data,
      };
      const { code, data } = await shieldInfo(b);
      if (code == 0) {
        console.log("状态", data);
        // this.pbtext = data.opFlag;
        if (data.opFlag == "0") {
          this.fl = false;
          this.pbtext = "已屏蔽";
        } else {
          this.fl = true;
          this.pbtext = "屏蔽此人";
        }
      }
    },
    // 用户信息
    async userInfo() {
      let token = local.get("access_token");
      const { code, data } = await query(this.$route.query.id, token);
      if (code == 0) {
        this.loginDate = data.user.loginDate; //最近登录时间
        this.iSstatus = data.user.status;
        this.UisVipMember = data.user.isVipMember;
        this.realAuthStatus = data.user.realAuthStatus;
        this.introduce = data.user.introduce; // 内心独白
        this.nativePlaceCityName = data.user.workAddrProvinceName; //籍贯
        this.age = data.user.age; //升高
        this.height = data.user.height; //身高
        let c = data.user.monthIncome; //收入
        this.nickName = data.user.nickName; //昵称
        this.id = `ID: ${data.user.memberId}`; //id
        this.sex = data.user.sex;
        this.info = data.user;
        this.avatar = ossL(data.user.avatar);
        this.mateSelection = data.user.mateSelection;
        //年龄区间处理
        if (
          this.mateSelection.ageMax == null &&
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "岁以上";
          this.mateSelection.ageMax = "";
        } else if (
          this.mateSelection.ageMax != null &&
          this.mateSelection.ageMin == null
        ) {
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁以下";
          this.mateSelection.ageMin = "";
        } else if (
          this.mateSelection.ageMax != null ||
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "-";
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁";
        }
        //身高
        if (
          this.mateSelection.heightMax == null &&
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin =
            this.mateSelection.heightMin + "cm以上";
          this.mateSelection.heightMax = "";
        } else if (
          this.mateSelection.heightMax != null &&
          this.mateSelection.heightMin == null
        ) {
          this.mateSelection.heightMax =
            this.mateSelection.heightMax + "cm以下";
          this.mateSelection.heightMin = "";
        } else if (
          this.mateSelection.heightMax != null ||
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin = this.mateSelection.heightMin + "-";
          this.mateSelection.heightMax = this.mateSelection.heightMax + "cm";
        }
        //收入
        if (
          this.mateSelection.monthIncomeMax == null &&
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "元以上";
          this.mateSelection.monthIncomeMax = "";
        } else if (
          this.mateSelection.monthIncomeMax != null &&
          this.mateSelection.monthIncomeMin == null
        ) {
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元以下";
          this.mateSelection.monthIncomeMin = "";
        } else if (
          this.mateSelection.monthIncomeMax != null ||
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "-";
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元";
        }
        //个人基础资料
        //星座
        if (data.user.constellatory) {
          this.constellations.forEach((item) => {
            if (data.user.constellatory == item.label) {
              this.info.constellatory = item.value;
              console.log(
                "体型",
                data.user.constellatory,
                this.info.constellatory
              );
            }
          });
        }

        //体型
        // console.log('体型',data.user.shape);
        this.shapes.forEach((item) => {
          if (data.user.shape == item.label) {
            // console.log('体型',data.user.shape);
            this.info.shape = item.value;
            console.log("体型", data.user.shape, this.info.shape);
          }
        });
        console.log("ti", Boolean(this.info.shape));
        //择偶体型
        this.shapes.forEach((item) => {
          if (data.user.mateSelection.shape == item.label) {
            this.mateSelection.shape = item.value;
          }
        });
        //有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.existsChildren == item.label) {
            this.info.existsChildren = item.value;
          }
        });
        //择偶有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.mateSelection.existsChildren == item.label) {
            this.mateSelection.existsChildren = item.value;
          }
        });
        //是否想要孩子
        this.wantChildrens.forEach((item) => {
          if (data.user.wantChildren == item.label) {
            this.info.wantChildren = item.value;
            console.log("0是否想要孩子0", this.info.wantChildren);
          }
        });
        this.wantChildrens.forEach((item) => {
          if (data.user.mateSelection.wantChildren == item.label) {
            this.mateSelection.wantChildren = item.value;
          }
        });
        //何时结婚
        this.marriedTimes.forEach((item) => {
          if (data.user.marriedTime == item.label) {
            this.info.marriedTime = item.value;
          }
        });
        //是否买车
        this.buyCarStatus.forEach((item) => {
          if (data.user.buyCarStatus == item.label) {
            this.info.buyCarStatus = item.value;
          }
        });
        //住房情况
        this.houseStatus.forEach((item) => {
          if (data.user.houseStatus == item.label) {
            this.info.houseStatus = item.value;
          }
        });
        //吸烟
        this.doSmakes.forEach((item) => {
          if (data.user.doSmake == item.label) {
            this.info.doSmake = item.value;
          }
        });
        // this.doSmakess.forEach((item) => {doSmakes
        this.doSmakes.forEach((item) => {
          if (data.user.mateSelection.doSmake == item.label) {
            this.mateSelection.doSmake = item.value;
          }
        });
        // 喝酒
        this.doDrinks.forEach((item) => {
          if (data.user.doDrink == item.label) {
            this.info.doDrink = item.value;
          }
        });
        // this.doDrinkss.forEach((item) => {
        this.doDrinks.forEach((item) => {
          if (data.user.mateSelection.doDrink == item.label) {
            this.mateSelection.doDrink = item.value;
          }
        });
        //  this.mateSelection.education  = ""
        this.educations.forEach((item) => {
          if (data.user.mateSelection.education == item.label) {
            this.mateSelection.education = item.value;
          }
        });
        //学历
        this.educations.forEach((item) => {
          if (data.user.education == item.label) {
            // this.mateSelection.education = item.value;
            this.education = item.value;
            this.info.education = item.value;
          }
        });

        //婚姻基本信息
        this.marriages.forEach((item) => {
          if (data.user.maritalStatus == item.label) {
            this.maritalStatus = item.value;
            this.info.maritalStatus = item.value;
          }
        });
        //婚姻择偶
        this.marriages.forEach((item) => {
          if (data.user.mateSelection.maritalStatus == item.label) {
            this.mateSelection.maritalStatus = item.value;
          }
        });

        // 薪资
        if (c == 0) {
          this.monthIncome = "3000元以下";
          this.info.monthIncome = "3000元以下";
        } else if (c == 1) {
          this.monthIncome = "3001-5000元";
          this.info.monthIncome = "3001-5000元";
        } else if (c == 2) {
          this.monthIncome = "5001-8000元";
          this.info.monthIncome = "5001-8000元";
        } else if (c == 3) {
          this.monthIncome = "8001-12000元";
          this.info.monthIncome = "8001-12000元";
        } else if (c == 4) {
          this.monthIncome = "12001-20000元";
          this.info.monthIncome = "12001-20000元";
        } else if (c == 5) {
          this.monthIncome = "20000-50000元";
          this.info.monthIncome = "20000-50000元";
        } else if (c == 6) {
          this.monthIncome = "50000元以上";
          this.info.monthIncome = "50000元以上";
        }
      } else {
        this.iSParameterError = true;
      }
      // console.log("===========", this.mateSelection);
      let mateSelectionLL = {
        ageMax: this.mateSelection.ageMax,
        ageMin: this.mateSelection.ageMin,
        shape: this.mateSelection.shape,
        monthIncomeMin: this.mateSelection.monthIncomeMin,
        monthIncomeMax: this.mateSelection.monthIncomeMax,
        maritalStatus: this.mateSelection.maritalStatus,
        isAvatar: this.mateSelection.isAvatar,
        heightMin: this.mateSelection.heightMin,
        heightMax: this.mateSelection.heightMax,
        existsChildren: this.mateSelection.existsChildren,
        education: this.mateSelection.education,
        doSmake: this.mateSelection.doSmake,
        doDrink: this.mateSelection.doDrink,
        wantChildren: this.mateSelection.wantChildren,
        workAddrCityId: this.mateSelection.workAddrCityId,
        workAddrCityName: this.mateSelection.workAddrCityName,
        workAddrDistrictId: this.mateSelection.workAddrDistrictId,
        workAddrDistrictName: this.mateSelection.workAddrDistrictName,
        workAddrProvinceId: this.mateSelection.workAddrProvinceId,
        workAddrProvinceName: this.mateSelection.workAddrProvinceName,
      };
      let aL = 0;
      for (let i in mateSelectionLL) {
        if (
          mateSelectionLL[i] == null ||
          mateSelectionLL[i] == "" ||
          mateSelectionLL == undefined
        ) {
          aL = aL + 1;
        }
      }
      if (aL == 20) {
        this.iSmateSelection = true;
      } else {
        this.iSmateSelection = false;
      }
    },
    //加载资料...
    async qing() {
      //加载当前用户信息
      let token = local.get("access_token");
      const D = await infocur(token);
      if (D.code == 0) {
        // console.log("数据 ----- ", D.data);
        this.isVipMember = D.data.user.isVipMember;
        this.isAvatar = D.data.user.isAvatar;
        this.UmemberId = D.data.user.memberId;
        this.isSex = D.data.user.sex;
        if (this.UmemberId != this.$route.query.id) {
          this.liulanAdd();
          // this.albumListY('0')
        } else {
          // this.albumListY('1')
        }
        this.login();
      }
      //信息详情页
      let Data = this.$route.query.id;
      if (Data == undefined || Data == null || Data == "") {
        this.iSParameterError = true;
        return;
      } else {
        this.iSParameterError = false;
      }

      //获取用户信息
      const { code, data } = await query(Data, token);
      if (code == 0) {
        this.loginDate = data.user.loginDate; //最近登录时间
        this.iSstatus = data.user.status;
        this.UisVipMember = data.user.isVipMember;
        this.realAuthStatus = data.user.realAuthStatus;
        this.introduce = data.user.introduce; // 内心独白
        // console.log("00000000000--------", this.introduce);
        this.nativePlaceCityName = data.user.workAddrProvinceName; //籍贯
        this.age = data.user.age; //升高
        this.height = data.user.height; //身高
        // let b = data.user.maritalStatus; //婚姻
        let c = data.user.monthIncome; //收入
        this.nickName = data.user.nickName; //昵称
        this.id = `ID: ${data.user.memberId}`; //id
        this.sex = data.user.sex;
        // console.log("00000", data.user);
        this.info = data.user;
        this.avatar = ossL(data.user.avatar);
        this.mateSelection = data.user.mateSelection;

        //年龄区间处理
        if (
          this.mateSelection.ageMax == null &&
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "岁以上";
          this.mateSelection.ageMax = "";
        } else if (
          this.mateSelection.ageMax != null &&
          this.mateSelection.ageMin == null
        ) {
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁以下";
          this.mateSelection.ageMin = "";
        } else if (
          this.mateSelection.ageMax != null ||
          this.mateSelection.ageMin != null
        ) {
          this.mateSelection.ageMin = this.mateSelection.ageMin + "-";
          this.mateSelection.ageMax = this.mateSelection.ageMax + "岁";
        }
        //身高
        if (
          this.mateSelection.heightMax == null &&
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin =
            this.mateSelection.heightMin + "cm以上";
          this.mateSelection.heightMax = "";
        } else if (
          this.mateSelection.heightMax != null &&
          this.mateSelection.heightMin == null
        ) {
          this.mateSelection.heightMax =
            this.mateSelection.heightMax + "cm以下";
          this.mateSelection.heightMin = "";
        } else if (
          this.mateSelection.heightMax != null ||
          this.mateSelection.heightMin != null
        ) {
          this.mateSelection.heightMin = this.mateSelection.heightMin + "-";
          this.mateSelection.heightMax = this.mateSelection.heightMax + "cm";
        }
        //收入
        if (
          this.mateSelection.monthIncomeMax == null &&
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "元以上";
          this.mateSelection.monthIncomeMax = "";
        } else if (
          this.mateSelection.monthIncomeMax != null &&
          this.mateSelection.monthIncomeMin == null
        ) {
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元以下";
          this.mateSelection.monthIncomeMin = "";
        } else if (
          this.mateSelection.monthIncomeMax != null ||
          this.mateSelection.monthIncomeMin != null
        ) {
          this.mateSelection.monthIncomeMin =
            this.mateSelection.monthIncomeMin + "-";
          this.mateSelection.monthIncomeMax =
            this.mateSelection.monthIncomeMax + "元";
        }
        //个人基础资料
        //星座
        if (data.user.constellatory) {
          this.constellations.forEach((item) => {
            if (data.user.constellatory == item.label) {
              this.info.constellatory = item.value;
              console.log(
                "体型",
                data.user.constellatory,
                this.info.constellatory
              );
            }
          });
        }

        //体型
        // console.log('体型',data.user.shape);
        this.shapes.forEach((item) => {
          if (data.user.shape == item.label) {
            // console.log('体型',data.user.shape);
            this.info.shape = item.value;
            console.log("体型", data.user.shape, this.info.shape);
          }
        });
        console.log("ti", Boolean(this.info.shape));
        //择偶体型
        this.shapes.forEach((item) => {
          if (data.user.mateSelection.shape == item.label) {
            this.mateSelection.shape = item.value;
          }
        });
        //有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.existsChildren == item.label) {
            this.info.existsChildren = item.value;
          }
        });
        //择偶有无孩子
        this.existsChildrens.forEach((item) => {
          if (data.user.mateSelection.existsChildren == item.label) {
            this.mateSelection.existsChildren = item.value;
          }
        });
        //是否想要孩子
        this.wantChildrens.forEach((item) => {
          if (data.user.wantChildren == item.label) {
            this.info.wantChildren = item.value;
            console.log("0是否想要孩子0", this.info.wantChildren);
          }
        });
        this.wantChildrens.forEach((item) => {
          if (data.user.mateSelection.wantChildren == item.label) {
            this.mateSelection.wantChildren = item.value;
          }
        });
        //何时结婚
        this.marriedTimes.forEach((item) => {
          if (data.user.marriedTime == item.label) {
            this.info.marriedTime = item.value;
          }
        });
        //是否买车
        this.buyCarStatus.forEach((item) => {
          if (data.user.buyCarStatus == item.label) {
            this.info.buyCarStatus = item.value;
          }
        });
        //住房情况
        this.houseStatus.forEach((item) => {
          if (data.user.houseStatus == item.label) {
            this.info.houseStatus = item.value;
          }
        });
        //吸烟
        this.doSmakes.forEach((item) => {
          if (data.user.doSmake == item.label) {
            this.info.doSmake = item.value;
          }
        });
        // this.doSmakess.forEach((item) => {
        this.doSmakes.forEach((item) => {
          if (data.user.mateSelection.doSmake == item.label) {
            this.mateSelection.doSmake = item.value;
          }
        });
        // 喝酒
        this.doDrinks.forEach((item) => {
          if (data.user.doDrink == item.label) {
            this.info.doDrink = item.value;
          }
        });
        // this.doDrinkss.forEach((item) => {
        this.doDrinks.forEach((item) => {
          if (data.user.mateSelection.doDrink == item.label) {
            this.mateSelection.doDrink = item.value;
          }
        });
        //  this.mateSelection.education  = ""
        this.educations.forEach((item) => {
          if (data.user.mateSelection.education == item.label) {
            this.mateSelection.education = item.value;
          }
        });
        //学历
        this.educations.forEach((item) => {
          if (data.user.education == item.label) {
            // this.mateSelection.education = item.value;
            this.education = item.value;
            this.info.education = item.value;
          }
        });

        //婚姻基本信息
        this.marriages.forEach((item) => {
          if (data.user.maritalStatus == item.label) {
            this.maritalStatus = item.value;
            this.info.maritalStatus = item.value;
          }
        });
        //婚姻择偶
        this.marriages.forEach((item) => {
          if (data.user.mateSelection.maritalStatus == item.label) {
            this.mateSelection.maritalStatus = item.value;
          }
        });

        // 薪资
        if (c == 0) {
          this.monthIncome = "3000元以下";
          this.info.monthIncome = "3000元以下";
        } else if (c == 1) {
          this.monthIncome = "3001-5000元";
          this.info.monthIncome = "3001-5000元";
        } else if (c == 2) {
          this.monthIncome = "5001-8000元";
          this.info.monthIncome = "5001-8000元";
        } else if (c == 3) {
          this.monthIncome = "8001-12000元";
          this.info.monthIncome = "8001-12000元";
        } else if (c == 4) {
          this.monthIncome = "12001-20000元";
          this.info.monthIncome = "12001-20000元";
        } else if (c == 5) {
          this.monthIncome = "20000-50000元";
          this.info.monthIncome = "20000-50000元";
        } else if (c == 6) {
          this.monthIncome = "50000元以上";
          this.info.monthIncome = "50000元以上";
        }
      } else {
        this.iSParameterError = true;
      }
      // console.log("===========", this.mateSelection);
      let mateSelectionLL = {
        ageMax: this.mateSelection.ageMax,
        ageMin: this.mateSelection.ageMin,
        shape: this.mateSelection.shape,
        monthIncomeMin: this.mateSelection.monthIncomeMin,
        monthIncomeMax: this.mateSelection.monthIncomeMax,
        maritalStatus: this.mateSelection.maritalStatus,
        isAvatar: this.mateSelection.isAvatar,
        heightMin: this.mateSelection.heightMin,
        heightMax: this.mateSelection.heightMax,
        existsChildren: this.mateSelection.existsChildren,
        education: this.mateSelection.education,
        doSmake: this.mateSelection.doSmake,
        doDrink: this.mateSelection.doDrink,
        wantChildren: this.mateSelection.wantChildren,
        workAddrCityId: this.mateSelection.workAddrCityId,
        workAddrCityName: this.mateSelection.workAddrCityName,
        workAddrDistrictId: this.mateSelection.workAddrDistrictId,
        workAddrDistrictName: this.mateSelection.workAddrDistrictName,
        workAddrProvinceId: this.mateSelection.workAddrProvinceId,
        workAddrProvinceName: this.mateSelection.workAddrProvinceName,
      };
      let aL = 0;
      for (let i in mateSelectionLL) {
        if (
          mateSelectionLL[i] == null ||
          mateSelectionLL[i] == "" ||
          mateSelectionLL == undefined
        ) {
          aL = aL + 1;
        }
      }
      if (aL == 20) {
        this.iSmateSelection = true;
      } else {
        this.iSmateSelection = false;
      }
      let a = {
        memberId: this.$route.query.id,
      };
      const C = await albumList(a);
      if (C.code == 0) {
        console.log("aaaa", C.data);
        C.data.map(async (item) => {
          this.items.push(
            Object.assign({}, item, {
              imgage: ossL(item.imgUrl),
            })
          );
        });
        console.log("图片", this.items);
        //测试
        let newDataList = [];
        let current = 0;
        if (this.items && this.items.length > 0) {
          for (let i = 0; i <= this.items.length - 1; i++) {
            if (i % 5 !== 0 || i === 0) {
              if (!newDataList[current]) {
                newDataList.push([this.items[i]]);
              } else {
                newDataList[current].push(this.items[i]);
              }
            } else {
              current++;
              newDataList.push([this.items[i]]);
            }
          }
        }
        console.log("______", newDataList);
        this.items = [...newDataList];
        C.data.map(async (item) => {
          this.listImg.push(ossL(item.imgUrl));
        });
        if (this.items.length == 0) {
          this.isImg = false;
          // this.isImg = true;
        } else {
          this.isImg = true;
        }
      }
    },
    //测试
    async albumListY(status) {
      let a = {
        // memberId: this.$route.query.id,
      };
      if (status == "0") {
        a = {
          memberId: this.$route.query.id,
        };
      } else {
        a = {};
      }
      const C = await albumList(a);
      if (C.code == 0) {
        console.log("aaaa", C.data);
        C.data.map(async (item) => {
          this.items.push(
            Object.assign({}, item, {
              imgage: ossL(item.imgUrl),
            })
          );
        });
        console.log("图片", this.items);
        //测试
        let newDataList = [];
        let current = 0;
        if (this.items && this.items.length > 0) {
          for (let i = 0; i <= this.items.length - 1; i++) {
            if (i % 5 !== 0 || i === 0) {
              if (!newDataList[current]) {
                newDataList.push([this.items[i]]);
              } else {
                newDataList[current].push(this.items[i]);
              }
            } else {
              current++;
              newDataList.push([this.items[i]]);
            }
          }
        }
        this.items = [...newDataList];
        C.data.map(async (item) => {
          this.listImg.push(ossL(item.imgUrl));
        });
        if (this.items.length == 0) {
          this.isImg = false;
        }
      }
    },
    async Like() {
      this.dataList = [];
      let a = {
        pageNum: this.PageNum,
        // pageSize: 4,
        pageSize: 3,
      };
      const E = await guessLike(a);
      if (E.code == 0) {
        console.log("数据", E.data);
        this.dataList = [];
        // this.dataList = E.data.records;
        E.data.records.map(async (item) => {
          this.dataList.push(
            Object.assign({}, item, {
              avatarImg: ossL(item.avatar),
            })
          );
        });
        this.Total = E.data.total;
        this.pages = E.data.pages;
      }
      // console.log('shuju1____________',this.dataList.length);
    },
    //发打招呼消息
    async send() {
      let Data = this.$route.query.id; //用户Id
      if (this.UmemberId == Data) {
        this.$message.error({
          message: "自己不能给自己打招呼",
          offset: 100,
        });
      } else if (this.isSex == this.sex) {
        this.$message.error({
          message: "同性不能打招呼",
          offset: 100,
        });
      } else {
        let d = {
          tid: this.$route.query.id,
        };
        loveLove(d);
        // let id = this.WebIM.conn.getUniqueId(); // 生成本地消息id
        let id = this.$WebIM.conn.getUniqueId(); // 生成本地消息id
        // let msg = new this.WebIM.message("txt", id); // 创建文本消息
        let msg = new this.$WebIM.message("txt", id); // 创建文本消息
        const _that = this;
        msg.set({
          // msg: "你好", // 消息内容
          msg: DB.gt(),
          to: `${this.$route.query.id}`, // -----------------------------------------------------------------------------------------(接收消息对象（用户id) )
          chatType: "singleChat", // 设置为单聊
          success: function (id, serverMsgId) {
            console.log("send private text Success", id, serverMsgId);
            _that.$message({
              message: "打招呼发送成功",
              type: "success",
              offset: 100,
            });
            _that.MessageStatus = false;
          },
          fail: function (e) {
            _that.$message({
              message: "打招呼发送失败",
              type: "error",
              offset: 100,
            });
            // 失败原因:
            // e.type === '603' 被禁言
            // e.type === '605' 群组不存在
            // e.type === '602' 不在群组或聊天室中
            // e.type === '504' 撤回消息时超出撤回时间
            // e.type === '505' 未开通消息撤回
            // e.type === '506' 没有在群组或聊天室白名单
            // e.type === '501' 消息包含敏感词
            // e.type === '502' 被设置的自定义拦截捕获
            // e.type === '503' 未知错误
            console.log("Send private text error", e);
          },
        });
        this.$WebIM.conn.send(msg.body);
      }
    },
    //红娘牵线
    async Services() {
      let Data = this.$route.query.id;
      let a = {
        targetId: Data,
      };
      const { code, msg } = await ApplicationServices(a);
      if (code == 0) {
        this.$message({
          message: msg,
          type: "success",
          offset: 100,
        });
        this.tkL();
      } else {
        this.$message({
          message: msg,
          type: "error",
          offset: 100,
        });
        this.tkL();
      }
    },
    pingbi() {
      this.mongolia = true;
    },
    report() {
      this.reportMongolia = true;
    },
    Btn(num) {
      if (num == 1) {
        this.mongolia = false;
      } else if (num == 2) {
        //举报理由
        this.reportMongolia = false;
        this.iSreportMongolia = true;
        // this.fileList = [];
        // this.value = "";
        // this.rise = "";
      }
    },
    iSreportMongoliaClose(i) {
      if (i == 1) {
        this.reportMongolia = true;
        this.iSreportMongolia = false;
      } else if (i == 2) {
        this.reportMongolia = false;
        this.iSreportMongolia = false;
        this.fileList = [];
        this.value = "";
        this.rise = "";
        this.YouName = "";
        this.YouPhone = "";
      }
    },
    async queren(id) {
      this.tkL();
      this.fl = !this.fl;
      let c;
      if (this.fl == true) {
        c = "1";
      } else {
        c = "0";
      }
      if (id == 1) {
        let Data = this.$route.query.id;
        // let a = local.get("access_token");
        let b = {
          // token: a,
          opFlag: c,
          shieldMemberId: `${Data}`,
        };
        const { code, msg } = await shield(b);
        if (code == 0) {
          this.$message({
            message: msg,
            type: "success",
            offset: 100,
          });
          this.pb();
        } else if (code == 1) {
          this.$message.error({
            message: msg,
            offset: 100,
          });
        }
        this.mongolia = false;
      } else if (id == 2) {
        if (this.value == "" || this.rise == "") {
          console.log("你好", this.$route.query.id);
        } else {
          console.log("提交");
          let a = {
            cellphone: this.YouPhone,
            informReason: this.rise,
            informType: this.value,
            informedId: this.$route.query.id,
            realName: this.YouName,
          };
          const { code, data, msg } = await informInfom(a);
          if (code == 0) {
            console.log("返回数据", data);
            this.$message({
              message: msg,
              type: "success",
              offset: 100,
            });
          } else {
            this.$message({
              message: msg,
              type: "warning",
              offset: 100,
            });
          }
        }
      }
    },
    //发消息
    messageList() {
      if (this.isVipMember == "0") {
        let rel = this.$router.resolve({
          path: "/n/yezaiMember",
        });
        window.open(rel.href, "_blank");
      } else if (this.isVipMember == "1") {
        let Data = this.$route.query.id;

        if (this.UmemberId == Data) {
          this.$message.error({
            message: "自己不能给自己发消息",
            offset: 100,
          });
        } else if (this.isSex == this.sex) {
          this.$message.error({
            message: "同性不能发消息",
            offset: 100,
          });
        } else {
          let rel = this.$router.resolve({
            path: "/chat",
            query: {
              objectID: Data,
            },
          });
          window.open(rel.href, "_blank");
        }
      }
    },
    jubao() {
      // console.log("456");
      this.reportMongolia = true;
    },
    //会员购买
    fn() {
      console.log(this.yezaiB);
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_blank");
    },
    //换一组
    go() {
      // console.log("换一组");
      // console.log('00',this.Total);
      // this.dataList = [];
      if (this.PageNum == this.pages) {
        this.PageNum = 1;
        this.Like();
      } else {
        ++this.PageNum;
        this.Like();
      }
    },
    //资料页
    ziliao(item) {
      let rel = this.$router.resolve({
        path: "/u",
        query: {
          id: item,
        },
      });
      window.open(rel.href, "_blank");
    },

    //句 举报图片--------
    UpLoad(file) {
      // const isLtSize = file.file.size / 1024 / 1024 < 1;
      // // console.log('数据',isLtSize,file);
      // if (!isLtSize) {
      //   // this.$message.error("");
      //   return this.$message({
      //     message: "上传图片大小不能超过 1MB!",
      //     type: "error",
      //     offset: 100,
      //   });
      // }
      const that = this;
      that.tkL();
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        client(that.dataObj)
          .multipartUpload(
            process.env.NODE_ENV === "production"
              ? `prod/report/${fileName}`
              : `dev/report/${fileName}`,
            // `/dev/report/${fileName}`
            file.file,
            {
              headers: {
                "Content-Type": "img/jpg",
              },
            }
          )
          .then(async (res) => {
            //上传成功返回值，可针对项目需求写其他逻辑
            that.fileList.push({
              name: res.name,
              url: ossL(res.name),
            });
          })
          .catch((err) => {
            console.log("err:", err);
          });
      }
      multipartUpload();
    },
    //删除数据(图片)
    removeByValue(arr, attr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i][attr] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    //删除图片
    removeByValueLL(arr, value) {
      arr.forEach((item, i) => {
        if (item == value) {
          arr.splice(i, 1); // 从下标 i 开始, 删除 1 个元素
        }
      });
    },
    async handleRemoveLL(file) {
      //数据处理
      let temp = file.url.lastIndexOf("?");
      let a = file.url.substring(0, temp);
      let tempL = a.lastIndexOf(".com");
      let b = a.substring(tempL + 4, a.length);
      this.removeByValue(this.fileList, "name", b);
      this.removeByValueLL(this.jubaoImg, b);
    },
    handleAvatarSuccess2(file) {
      console.log("成功", file);
    },
    //举报
    async juBaoQueren(id) {
      this.jubaoImg = [];
      if (id == 2) {
        //举报类型
        if (this.value == "") {
          this.JulX = true;
        } else {
          this.JulX = false;
        }
        //举报理由
        if (this.rise == "") {
          this.iSliyou = true;
        } else {
          this.iSliyou = false;
        }
        //截图证据
        if (this.fileList.length == 0) {
          this.iSjietu = true;
        } else {
          this.iSjietu = false;
        }

        for (let i = 0; i < this.fileList.length; i++) {
          if (this.jubaoImg.indexOf(this.fileList[i]) < 0)
            this.jubaoImg.push(this.fileList[i].name);
        }
        this.ImgList = this.jubaoImg.join(",");
        //  满足条件调用举报接口
        if (this.value != "" && this.rise != "" && this.fileList.length != 0) {
          let a = {
            cellphone: this.YouPhone, //电话
            informReason: this.rise, //理由
            informType: this.value, //类型
            informedId: this.$route.query.id, //举报id
            realName: this.YouName, //举报昵称
            imgUrl: this.ImgList, //截图
          };
          const { code, msg } = await informInfom(a); //msg
          const _that = this;
          if (code == 0) {
            // this.jubaoImg = [];
            // this.tkL();

            setTimeout(function () {
              _that.$message({
                message: msg,
                type: "success",
                offset: 100,
              });
            }, 1000);
          } else {
            setTimeout(function () {
              _that.$message({
                message: msg,
                type: "error",
                offset: 100,
              });
            }, 1000);
          }
          //举报理由
          this.jubaoImg = [];
          this.tkL();
          this.reportMongolia = false;
          this.fileList = [];
          this.value = "";
          this.rise = "";
        }
      }
    },
    //控制页面 弹层显示时调用 noScroll()停止页面滚动 ，弹层消失时调用 canScroll()开启页面滚动
    //停止页面滚动
    noScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面滑动
    },
    //开启页面滚动
    canScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
  },
  //maskOff 换成你的弹窗变量 监听弹窗开启 关闭
  watch: {
    reportMongolia(newVal) {
      console.log(newVal);
      if (newVal) {
        this.noScroll();
      } else {
        this.canScroll();
      }
    },
    iSParameterError(newVal) {
      if (newVal) {
        this.noScroll();
      } else {
        this.canScroll();
      }
    },
    iSreportMongolia(newVal) {
      if (newVal) {
        this.noScroll();
      } else {
        this.canScroll();
      }
    },
    introduce: function () {
      this.$nextTick(
        function () {
          console.log("nextTick");
          // 判断介绍是否超过四行
          let rem = parseFloat(this.getRem());
          console.log("watch 中的rem", rem);
          if (!this.$refs.desc) {
            console.log("desc null");
            return;
          }
          let descHeight = window
            .getComputedStyle(this.$refs.desc)
            .height.replace("px", "");
          console.log("descHeight:" + descHeight);
          console.log("如果 descHeight 超过" + 1.25 * rem + "就要显示展开按钮");
          if (descHeight > 1.25 * rem) {
            console.log("超过了四行");
            // 显示展开收起按钮
            this.showExchangeButton = true;
            this.exchangeButton = true;
            // 不是显示所有
            this.showTotal = false;
          } else {
            // 不显示展开收起按钮
            this.showExchangeButton = false;
            // 没有超过四行就显示所有
            this.showTotal = true;
            console.log("showExchangeButton", this.showExchangeButton);
            console.log("showTotal", this.showTotal);
          }
        }.bind(this)
      );
    },
  },
};
</script>


 


<style lang="less" scoped>
.content {
  width: 100%;
  padding-top: 68px;
  display: flex;
  justify-content: center;
  // flex-direction: column;//账户异常
  background: url("../../assets/images/slices/bg.png") repeat-x;

  .bgi {
    // height: 400px;
    height: 366px;
    display: flex;
    justify-content: center;
    width: 1200px;

    .info {
      width: 1200px;
      position: relative;

      .info-z {
        margin-top: 100px;
        // margin-left: 270px;
        margin-left: 244px;
        width: 635px;
        display: flex;
        justify-content: space-between;

        .info-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 82px;

          // margin-left: 20px;
          .left-a {
            display: flex;
            align-items: center;

            color: #ffffff;
            line-height: 33px;

            .left-nickname {
              font-size: 22px;
              // margin-right: 34px;
            }

            .left-icon {
              display: flex;
              align-items: center;
              margin-left: 8px;

              img {
                // width: 22px;
                // height: 22px;
                margin: 0px 5px;
              }
            }

            .Btn-box {
              margin-left: 18px;
              // width: 91px;
              // height: 33px;
              width: 80px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              color: #fd686eff;
              font-size: 14px;
              border-radius: 17px;
              cursor: pointer;

              img {
                // width: 16px;
                width: 14px;
                height: 12px;
                margin: 0;
                margin-right: 4px;
                // height: 16px;
              }
            }
          }

          .left-id {
            font-size: 12px;
            color: #ffffff;
          }

          .left-zi {
            font-size: 14px;
            color: #ffffff;
          }
        }

        .info-right {
          // margin-left: 108px;
          // margin-left: 174px;
          // margin-top: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .right-a {
            display: flex;
            font-size: 12px;
            color: #ffffff;
            height: 30px;
            align-items: center;

            .a {
              display: flex;
              cursor: pointer;

              img {
                width: 13px;
                height: 13px;
                margin-right: 2px;
              }
            }

            .mongolia {
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 9999;

              .xg-phone {
                z-index: 889;
                box-sizing: border-box;
                background-color: #ffffffff;
                position: fixed;
                top: 256px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                border-radius: 8px;
                width: 440px;
                height: 214px;
                background: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;

                .top {
                  font-size: 18px;
                  height: 54px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  box-sizing: border-box;
                  font-weight: bold;
                  // color: #333333ff;
                  width: 100%;
                  // color: #333333ff;
                  background: #f5f5f5;
                  color: #666;
                  font-size: 14px;
                  border-radius: 4px 4px 0px 0px;

                  p {
                    text-align: center;
                  }
                }

                .line {
                  width: 100%;
                  height: 1px;
                  background-color: #ecececff;
                }

                .title {
                  text-align: center;
                  // padding-top: 36px;
                  padding-top: 30px;
                  box-sizing: border-box;
                  color: #333333ff;
                  width: 316px;
                  height: 44px;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 22px;
                }

                .Btn {
                  display: flex;
                  justify-content: center;
                  margin-top: 54px;

                  .cancel,
                  .confirm {
                    margin-left: 20px;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 140px;
                    height: 40px;
                    border: 1px solid;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid #adadad;
                  }

                  .confirm {
                    border: none;
                    background: #fd686e;
                    color: #ffffff;
                  }
                }

                .icon {
                  cursor: pointer;
                  display: flex;
                  color: #adadadff;
                  font-size: 18px;
                  position: absolute;
                  top: 20px;
                  right: 20px;
                }
              }
            }
          }

          .right-b {
            margin-top: 20px;
            display: flex;
            font-size: 14px;
            color: #ffffff;

            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }

          .right-b-a {
            cursor: pointer;
          }
        }
      }

      .info-m {
        position: absolute;
        top: 190px;
        left: 0px;
        // height: 226px;
        // width: 890px;
        width: 880px;
        height: 202px;
        background-color: #ffffff;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #ececec;

        //ceshi
        .info-info-lunbo {
          display: flex;
          // margin-left: 272px;
          margin-left: 234px;
          // margin-left: 314px;
          margin-bottom: 12px;

          /deep/ .el-carousel__arrow {
            display: block !important;
            position: relative;
          }

          /deep/ .el-carousel__arrow--left {
            left: -40px;
            font-size: 20px !important;
            // background-color: #fff;
            background-color: rgba(255, 255, 255, 0);

            i {
              color: #666;
            }
          }

          /deep/ .el-carousel__arrow--right {
            position: absolute;
            right: 10px;
            // z-index: 99999;
            font-size: 20px !important;
            background-color: rgba(255, 255, 255, 0);

            // background-color: #fff;
            i {
              color: #666;
            }
          }

          /deep/ .el-carousel {
            // width: 520px;
            padding-left: 50px;
          }

          /deep/ .el-carousel__arrow:hover {
            background-color: none;
          }

          // /deep/
          .el-car-item {
            // width: 520px;
            display: flex;

            .top-img {
              width: 100px;
              height: 100px;
              margin-right: 10px;
              display: flex;
              align-items: flex-end;
              // background-color: #fff7f8;
              // background-color: #feeff0;
              justify-content: center;
              background-color: #000;

              /deep/ .el-image__inner {
                cursor: url(../../assets/images/tu/big.ea3f1e7.png), pointer;
                width: auto !important;
              }

            }
          }
        }

        //轮播图
        .info-lunbo {
          display: flex;
          margin-left: 272px;
          margin-bottom: 12px;

          .card-carousel-wrapper {
            display: flex;
            align-items: center;
            // justify-content: center;
            color: #666a73;
            // z-index: 9999;
          }

          .card-carousel {
            display: flex;
            // justify-content: center;
            // width: 450px;
            width: 520px;
          }

          .card-carousel--overflow-container {
            overflow: hidden;
          }

          .card-carousel--nav__left,
          .card-carousel--nav__right {
            display: inline-block;
            width: 15px;
            height: 15px;
            // padding: 10px;
            padding: 5px;
            box-sizing: border-box;
            border-top: 2px solid #fd686e;
            border-right: 2px solid #fd686e;
            cursor: pointer;
            // margin: 0 10px;
            transition: transform 150ms linear;
          }

          .card-carousel--nav__left[disabled],
          .card-carousel--nav__right[disabled] {
            opacity: 0.2;
            border-color: black;
          }

          .card-carousel--nav__left {
            transform: rotate(-135deg);
          }

          .card-carousel--nav__left:active {
            transform: rotate(-135deg) scale(0.9);
          }

          .card-carousel--nav__right {
            transform: rotate(45deg);
          }

          .card-carousel--nav__right:active {
            transform: rotate(45deg) scale(0.9);
          }

          .card-carousel-cards {
            display: flex;
            transition: transform 150ms ease-out;
            transform: translatex(0px);
          }

          .card-carousel-cards .card-carousel--card {
            margin: 0 10px;
            cursor: pointer;
            box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
              0 2px 2px 0 rgba(40, 44, 53, 0.08);
            background-color: #fff;
            border-radius: 4px;
            z-index: 3;
            // z-index: 9999;
            margin-bottom: 2px;
          }

          .card-carousel-cards .card-carousel--card:first-child {
            margin-left: 0;
          }

          .card-carousel-cards .card-carousel--card:last-child {
            margin-right: 0;
          }

          .card-carousel-cards .card-carousel--card img {
            vertical-align: bottom;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            transition: opacity 150ms linear;
            user-select: none;
          }

          .card-carousel-cards .card-carousel--card img:hover {
            opacity: 0.5;
          }

          .card-carousel-cards .card-carousel--card--footer {
            /deep/ .el-image-viewer__canvas img {
              max-height: 50%;
              max-width: 50%;
            }

            border-top: 0;

            // padding: 7px 15px;
            img {
              width: 100px;
              height: 100px;
            }
          }

          .card-carousel-cards .card-carousel--card--footer p {
            padding: 3px 0;
            margin: 0;
            margin-bottom: 2px;
            font-size: 19px;
            font-weight: 500;
            color: #2c3e50;
            user-select: none;
          }

          .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2) {
            font-size: 12px;
            font-weight: 300;
            padding: 6px;
            background: rgba(40, 44, 53, 0.06);
            display: inline-block;
            position: relative;
            margin-left: 4px;
            color: #666a73;
          }

          .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):before {
            content: "";
            float: left;
            position: absolute;
            top: 0;
            left: -12px;
            width: 0;
            height: 0;
            border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
            border-style: solid;
            border-width: 12px 12px 12px 0;
          }

          .card-carousel-cards .card-carousel--card--footer p:nth-of-type(2):after {
            content: "";
            position: absolute;
            top: 10px;
            left: -1px;
            float: left;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: white;
            box-shadow: -0px -0px 0px #004977;
          }

          //轮播tu结束--=-=--=-
        }

        .no-photo {
          display: flex;
          margin-left: 280px;
          margin-bottom: 65px;
          margin-top: 46px;
          font-size: 14px;
          color: #999;
          align-items: center;

          .icon {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            width: 13px;
            height: 13px;
            background: #fff;
            border: 1px solid #c6c6c6;
            border-radius: 50%;
          }

          .icon::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -3px 0 0 -2px;
            width: 5px;
            height: 5px;
            background: #d8d8d8;
            border-radius: 50%;
          }
        }

        // align-items: flex-end;
        .big-img {
          // width: 220px;
          // height: 220px;
          width: 200px;
          height: 200px;
          position: absolute;
          top: -99px;
          left: 32px;
          border-radius: 6px;
          // overflow: hidden;
          // background: center center no-repeat #f5f5f5;
          // background-size: cover;
        }

        .info-bottom {
          // height: 84px;
          height: 75px;
          background-color: #fff7f8ff;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 12px 0px;

          img {
            margin-right: 4px;
          }

          div {
            width: 297px;
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 60px;
            height: 34px;
            // font-size: 14px;
            font-size: 16px;
            border-right: 1px solid #ccc;
            cursor: pointer;
            color: #666;

            img {
              width: 30px;
              height: 30px;
            }
          }

          div:nth-child(3) {
            border: none;
          }
        }
      }

      .info-a {
        width: 290px;
        height: 320px;
        position: absolute;
        top: 190px;
        right: 0px;
        border-radius: 8px;
        box-sizing: border-box;

        .a-box {
          height: 100px;
          // background: url("../../assets/images/info/xinbgi.png") no-repeat;
          border-radius: 8px 8px 0px 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;

          p:nth-child(1) {
            width: 144px;
            height: 50px;
            font-size: 32px;
            font-weight: 600;
            color: #ffffff;
            line-height: 50px;
          }

          p:nth-child(2) {
            width: 238px;
            height: 36px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            text-align: center;
          }
        }

        .b-box {
          // height: 220px;
          // height: 197px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          ul {
            li.active {
              color: #e99734ff;
            }

            li {
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              // justify-content: space-around;
              // padding: 0px 20px;
              // padding: 0px 10px;
              padding: 0px 20px;
              position: relative;
              align-items: center;
              height: 40px;
              color: #666;

              // position: relative;
              // p:nth-child(2) {
              //   font-size: 12px;
              //   position: absolute;
              //   left: 130px;
              // }
              .b-box_day {
                font-size: 12px;
                position: absolute;
                // left: 125px;
                left: 100px;
              }

              .Hollow-circle {
                display: flex;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px solid #adadadff;
              }

              i {
                cursor: pointer;
              }

              // p:nth-child(4) {
              //   margin-right: 0px;
              // }
              .remai {
                position: absolute;
                // left: 146px;
                // right: 77px;
                right: 96px;
                margin-right: 0px;
                font-size: 12px;
                color: #ffffff;
                line-height: 17px;
                width: 28px;
                height: 19px;
                background: #ff8162;
                border-radius: 1px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .b-box_money {
                margin-right: 25px;
                font-size: 16px;
              }

              .b-box_img {
                position: absolute;
                // right: 10px;
                right: 16px;
                cursor: pointer;
              }
            }
          }

          .mai-box {
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-top: 40px;
            margin-top: 15px;
            margin-bottom: 10px;

            .mai {
              z-index: 1;
              // width: 166px;
              // height: 44px;
              width: 150px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              // font-size: 18px;
              font-size: 16px;
              color: #333333;
              font-weight: bold;
              background: linear-gradient(90deg, #ffeb27 0%, #ffcd21 100%);
              border-radius: 22px;
            }
          }
        }
      }
    }
  }
}

.bg-info {
  padding-top: 105px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: #fcfcfcff;

  .info-zi {
    width: 1200px;
    // height: 400px;
    display: flex;
    justify-content: space-between;
    // background-color: aquamarine;
    position: relative;

    .zi-left {
      // width: 890px;
      width: 880px;
      // height: 700px;
      border: 1px solid #ecececff;
      border-radius: 8px;
      background-color: #fff;
      // position: absolute;
      // top: -10px;
      margin-right: 20px;
      position: relative;
      top: -60px;
      padding: 20px;
      box-sizing: border-box;

      .left-box {
        position: relative;

        p {
          // font-size: 18px;
          font-size: 16px;
          // font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        p::before {
          content: "";
          width: 4px;
          height: 18px;
          // background: #fd686e;
          position: absolute;
          top: 0px;
          left: -10px;
        }

        .box1 {
          padding: 10px;
          text-align: justify;
          min-height: 50px;
          display: flex;
          align-items: center;
          // line-height: 20px;
          text-indent: 2em;
          color: #666;
        }

        .box2 {
          margin-bottom: 18px;
          margin-top: 10px;

          .info_div {
            display: flex;
            flex-wrap: wrap;

            .m-btn {
              display: inline-block;
              height: 28px;
              line-height: 28px;
              padding: 0 20px;
              background: #f2f2f2;
              color: #666;
              border-radius: 15px;
              margin-left: 10px;
              margin-top: 10px;
            }

            .purple {
              background: #feeff0ff;
            }
          }

          .info_box {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;

            .m-btn {
              display: inline-block;
              height: 28px;
              line-height: 28px;
              padding: 0 20px;
              background: #f2f2f2;
              border-radius: 15px;
              margin-left: 10px;
              margin-top: 10px;
            }

            .purple {
              background: #eaebffff;
            }
          }
        }

        .box3 {
          margin: 18px 0px;
          margin-left: 10px;
          min-height: 100px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          p {
            font-size: 14px;
            color: #666;
            font-weight: 400;
            min-width: 400px;
          }

          span {
            color: #333;
          }

          .jjj {
            width: 500px;
          }
        }

        .box4 {
          margin-top: 10px;
          min-height: 45px;
          // background-color: aquamarine;
          display: flex;
          flex-wrap: wrap;

          .m-btn {
            display: inline-block;
            height: 28px;
            line-height: 28px;
            padding: 0 20px;
            background: #f5f5f5;
            color: #666;
            border-radius: 15px;
            margin-left: 10px;
            margin-top: 10px;
          }

          .purple {
            background: #f5f5f5;
          }
        }

        //测试
        .total-introduce {
          height: auto;
          overflow: hidden;
          font-size: 14px;
          color: #434343;
          margin: 10px;

          .intro-content {
            .merchant-desc {
              width: 100%;
              line-height: 21px;
            }
          }

          .unfold {
            display: block;
            z-index: 11;
            float: right;
            width: 40px;
            height: 21px;
            cursor: pointer;

            p {
              margin: 0;
              line-height: 21px;
              // color: #7fbe87;
              font-size: 14px;
              color: #999;

            }
          }
        }

        .detailed-introduce {
          font-size: 14px;
          color: #434343;
          position: relative;
          overflow: hidden;
          margin: 10px;

          .intro-content {
            // 最大高度设为4倍的行间距
            max-height: 84px;
            line-height: 21px;
            word-wrap: break-word;
            /*强制打散字符*/
            word-break: break-all;
            background: #ffffff;
            /*同背景色*/
            color: #ffffff;
            overflow: hidden;

            .merchant-desc {
              width: 100%;
              line-height: 21px;
            }

            &:after,
            // 这是展开前实际显示的内容
            &:before {
              content: attr(title);
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              color: #434343 // overflow: hidden;
            }

            // 把最后最后一行自身的上面三行遮住
            &:before {
              display: block;
              overflow: hidden;
              z-index: 1;
              max-height: 63px;
              background: #ffffff;
            }

            &:after {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: 81px;
              /*截取行数*/
              -webkit-line-clamp: 4;
              text-overflow: ellipsis;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              /*行首缩进字符数，值为[(截取行数-1)*尾部留空字符数]*/
              text-indent: -12em;
              /*尾部留空字符数*/
              padding-right: 4em;
            }

            .unfold {
              z-index: 11;
              width: 40px;
              height: 21px;
              outline: 0;
              position: absolute;
              right: 0;
              bottom: 0;
              cursor: pointer;

              p {
                margin: 0;
                line-height: 21px;
                // color: #7fbe87;
                font-size: 14px;
                color: #999;
              }
            }
          }
        }

        //测试
      }
    }

    .zi-right-Mo {
      margin-top: 94px !important;
    }

    .zi-right {
      // margin-top: 40px;
      margin-top: 30px;
      width: 290px;
      // height: 328px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #ececec;
      box-sizing: border-box;
      padding: 20px;
      padding-top: 16px;
      // height: 505px;
      // height: 448px;
      height: 346px;

      // position: absolute;
      .zi-right-box {
        display: flex;
        justify-content: space-between;
        height: 22px;
        align-items: center;

        .title {
          font-size: 16px;
          color: #666;
          font-weight: bold;
        }

        // margin-bottom: 34px;
        .box1 {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-right: 6px;
          }

          .box1-img {
            width: 14px;
            height: 15px;
          }
        }
      }

      div {
        ul {
          li {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            cursor: pointer;

            .left-box {

              // img {
              //   width: 76px;
              //   height: 76px;
              // }
              img {
                width: 75px;
                height: 75px;
              }
            }

            .right-box {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 158px;

              .box-name {
                display: flex;

                p {
                  color: #666;
                  font-size: 14px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  // width: 70px;
                }

                img {
                  width: 16px;
                  height: 16px;
                  margin: 0px 2px;
                }
              }

              .age {
                color: #999;
                font-size: 12px;
              }

              .jj {
                font-size: 12px;
                line-height: 17px;
                width: 162px;
                color: #666;
                //省略号
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}

//账户异常
.box {
  .abnormal {
    padding-top: 68px;
    display: flex;
    justify-content: center;
    background: url("../../assets/images/slices/bg.png") no-repeat;

    .bgi {
      height: 400px;
      display: flex;
      justify-content: center;
      width: 1200px;

      .info {
        width: 1200px;
        position: relative;

        .info-z {
          margin-top: 100px;
          margin-left: 300px;
          display: flex;

          .info-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 82px;
            margin-left: 20px;

            .left-a {
              display: flex;
              align-items: center;
              height: 33px;
              font-size: 24px;
              color: #ffffff;
              line-height: 33px;

              .left-nickname {
                font-size: 22px;
              }

              .left-icon {
                display: flex;
                align-items: center;
                margin-left: 8px;

                img {
                  // width: 22px;
                  // height: 22px;
                  margin: 0px 5px;
                }
              }

              .b {
                cursor: not-allowed;
              }

              .Btn-box {
                margin-left: 18px;
                width: 91px;
                height: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                color: #fd686eff;
                font-size: 16px;
                border-radius: 17px;

                img {
                  width: 16px;
                  margin: 0;
                  margin-right: 4px;
                  height: 16px;
                }
              }
            }

            .left-id {
              font-size: 14px;
              color: #ffffff;
            }

            .left-zi {
              font-size: 16px;
              color: #ffffff;
            }
          }

          .info-right {
            margin-left: 108px;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .right-a {
              display: flex;
              font-size: 14px;
              color: #ffffff;
              font-size: 12px;
              height: 30px;
              align-items: center;

              .a {
                display: flex;
                cursor: pointer;

                img {
                  width: 13px;
                  height: 13px;
                  margin-right: 2px;
                }
              }

              .b {
                cursor: not-allowed;
              }

              .mongolia {
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 9999;

                .xg-phone {
                  z-index: 889;
                  box-sizing: border-box;
                  background-color: #ffffffff;
                  position: fixed;
                  top: 256px;
                  left: 50%;
                  -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
                  border-radius: 8px;
                  width: 440px;
                  height: 214px;
                  background: #ffffff;
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .top {
                    font-size: 18px;
                    height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    font-weight: bold;
                    color: #333333ff;

                    p {
                      text-align: center;
                    }
                  }

                  .line {
                    width: 100%;
                    height: 1px;
                    background-color: #ecececff;
                  }

                  .title {
                    text-align: center;
                    padding-top: 36px;
                    box-sizing: border-box;
                    color: #333333ff;
                    width: 316px;
                    height: 44px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                  }

                  .Btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 54px;

                    .cancel,
                    .confirm {
                      margin-left: 20px;
                      color: #000;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 140px;
                      height: 40px;
                      border: 1px solid;
                      cursor: pointer;
                      border-radius: 4px;
                      border: 1px solid #adadad;
                    }

                    .confirm {
                      border: none;
                      background: #fd686e;
                      color: #ffffff;
                    }
                  }

                  .icon {
                    cursor: pointer;
                    display: flex;
                    color: #adadadff;
                    font-size: 18px;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                  }
                }
              }
            }

            .right-b {
              margin-top: 20px;
              display: flex;
              font-size: 16px;
              color: #ffffff;
            }
          }
        }

        .info-m {
          position: absolute;
          top: 190px;
          left: 0px;
          height: 226px;
          width: 890px;
          background-color: #fff;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;

          .big-img {
            width: 220px;
            height: 220px;
            position: absolute;
            top: -99px;
            left: 38px;
          }

          .info-bottom {
            height: 84px;
            background-color: #fff7f8ff;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 12px 0px;

            img {
              margin-right: 4px;
            }

            div {
              width: 297px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 60px;
              font-size: 16px;
              border-right: 1px solid #ecececff;
              cursor: pointer;
              color: #777777ff;
            }

            div:nth-child(3) {
              border: none;
            }
          }
        }

        .info-b {
          position: absolute;
          top: 200px;
          left: 0px;
          width: 1200px;
          height: 740px;
          background-color: #ffffff;
          // border: 1px solid red;
          width: 1180px;
          height: 740px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #ececec;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .big-img {
            position: absolute;
            top: -100px;
            left: 60px;
            width: 220px;
            height: 220px;
          }

          p {
            margin-top: 10px;
            width: 570px;
            height: 48px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #333333;
            line-height: 24px;

            span {
              color: #549cebff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .bgc {
    height: 576px;
    background-color: #f7f7f7;
  }
}

/deep/ .el-upload--picture-card {
  line-height: 74px !important;
}

//举报蒙层
.jubao-mongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;

  .xg-phone {
    z-index: 898;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 500px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 14px;

    // /deep/ .el-scrollbar,
    // .el-select-dropdown {
    //   z-index: 9999 !important;
    // }
    span {
      font-size: 14px;
    }

    .top {
      font-size: 14px;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-weight: bold;
      color: #333333ff;

      p {
        text-align: center;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #ecececff;
    }

    .title {
      margin-top: 18px;
      align-items: center;
      box-sizing: border-box;
      color: #333333ff;
      font-size: 14px;
      justify-content: space-between;
      display: flex;
      position: relative;

      /deep/ .el-select {
        width: 320px;
      }

      /deep/ .el-input__inner::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #adadad;
      }

      /deep/ .el-select-dropdown,
      .el-popper,
      .popper_select {
        z-index: 9999 !important;
      }

      .tip {
        position: absolute;
        font-size: 12px;
        top: 42px;
        left: 80px;
        color: red;
      }
    }

    .title-tip {
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      color: #333333ff;
    }

    .liyou {
      margin-top: 18px;
      box-sizing: border-box;
      color: #333333ff;
      display: flex;
      justify-content: space-between;
      position: relative;

      .title-reason {
        position: relative;

        #text {
          padding: 12px 20px;
          width: 320px;
          height: 150px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 2px;
          /*去掉右下角的斜杠，不允许文本框缩放*/
          resize: none;
          /*将边框去掉*/
          border: none;
          border-radius: 2px;
          /*设置字体大小*/
          font-size: 14px;
          /* placeholder位置 提示靠左靠右居中等 */
          text-align: left;
          /* 其他样式还有不少，没有用就没查，需要可自行查看 */
          position: relative;
        }

        .number {
          position: absolute;
          color: #adadadff;
          bottom: 7px;
          right: 16px;
        }

        textarea::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #adadad;
        }

        textarea:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #adadad;
        }

        textarea::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #adadad;
        }

        textarea::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #adadad;
        }
      }

      .liyou-tip {
        position: absolute;
        font-size: 12px;
        bottom: -14px;
        left: 80px;
        color: red;
      }
    }

    .jietu {
      margin-top: 18px;
      // justify-content: center;
      box-sizing: border-box;
      color: #333333ff;
      // width: 316px;
      font-size: 14px;
      color: #333333;
      display: flex;
      flex-direction: column;
      position: relative;

      span {
        margin-right: 10px;
      }

      .jietu-a {
        margin-top: 10px;
      }

      /deep/ .el-upload,
      .el-upload-list {
        width: 68px;
        height: 68px;
        line-height: 68px;
      }

      /deep/ .el-upload-list {
        li {
          width: 68px;
          height: 68px;
        }
      }

      .jietu-tip {
        position: absolute;
        font-size: 12px;
        bottom: -15px;
        left: 0px;
        color: red;
      }
    }

    .info {
      margin-top: 18px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      span {
        color: #333333ff;
        margin-right: 10px;
      }

      /deep/ .el-input {
        width: 320px;
        height: 40px;
      }
    }

    .Btn {
      display: flex;
      justify-content: center;
      margin-top: 54px;
      margin-bottom: 40px;

      .cancel,
      .confirm {
        margin-left: 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        border: 1px solid;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #adadad;
      }

      .confirm {
        border: none;
        background: #fd686e;
        color: #ffffff;
      }
    }

    .icon {
      cursor: pointer;
      display: flex;
      color: #adadadff;
      font-size: 18px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}


// 提示头像上传
.tks {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  .SF {
    position: fixed;
    top: 200px;
    left: 40%;
    // width: 420px;
    width: 380px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Sf-title {
      line-height: 44px;
      background: #f3f3f3;
      text-align: center;
      color: #999;
      font-size: 16px;
      // width: 420px;
      width: 100%;
      position: relative;

      /deep/.el-icon-close {
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
      }
    }

    .Sf-content {
      margin-top: 38px;
      margin-bottom: 34px;
    }

    .Sf-Btn {
      display: flex;
      margin-bottom: 34px;

      div {
        width: 140px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .Sf-Btn-left {
        border-radius: 4px;
        border: 1px solid #adadad;
        color: #666666;
        margin-right: 20px;
      }

      .Sf-Btn-right {
        background: #fd686e;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}

.boc {
  opacity: 0;
}

.ParameterError {
  width: 100%;
  height: 100%;
  // opacity: .5;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  .SF {
    position: fixed;
    top: 200px;
    left: 40%;
    // width: 420px;
    width: 380px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Sf-title {
      line-height: 44px;
      background: #f3f3f3;
      text-align: center;
      color: #999;
      font-size: 16px;
      // width: 420px;
      width: 100%;
      position: relative;

      /deep/.el-icon-close {
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
      }
    }

    .Sf-content {
      margin-top: 38px;
      margin-bottom: 34px;
    }

    .Sf-Btn {
      display: flex;
      margin-bottom: 34px;

      div {
        width: 140px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .Sf-Btn-left {
        border-radius: 4px;
        border: 1px solid #adadad;
        color: #666666;
        margin-right: 20px;
      }

      .Sf-Btn-right {
        background: #fd686e;
        border-radius: 4px;
        color: #fff;
      }
    }
  }

}
</style>
