// 1 + Maht.random() *( 9 - 1) /* 1 - 9 的随机数 */
//测试
// import DB from "@/assets/js/Greeting.js";
// console.log('ss',DB.gt());//随机打招呼语
var time_range = function (beginTime, endTime) {
    var strb = beginTime.split(":");
    if (strb.length != 2) {
        return false;
    }

    var stre = endTime.split(":");
    if (stre.length != 2) {
        return false;
    }
    var b = new Date();
    var e = new Date();
    var n = new Date();

    b.setHours(strb[0]);
    b.setMinutes(strb[1]);
    e.setHours(stre[0]);
    e.setMinutes(stre[1]);

    if (n.getTime() - b.getTime() > 0 && n.getTime() - e.getTime() < 0) {
        return true;
    } else {
        return false;
    }
}
const DB = {
    gt() {
        console.log('时间', time_range("13:01", "18:00"));
        if (time_range("06:00", "12:00")) {
            return '早上好呀!可以聊聊吗？'
        } else if (time_range("12:01", "13:00")) {
            return '中午好呀!我觉得我们都符合彼此的择偶要求哦~'
        } else if (time_range("13:01", "18:00")) {
            let numC = parseInt(Math.random() * 2)
            let greetingC = [
                '我们的择偶要求好像挺一致的，可以聊聊吗？',
                '下午好呀!可以聊聊吗？',
            ]
            return greetingC[numC]
        } else if (time_range("18:01", "00:00")) {
            return '晚上好呀!可以聊聊吗？'
        } else {
            return '我们好像都符合彼此的择偶要求哦~'
        }
    }
}
export default DB